<template>
  <div>
    <div class="d-flex justify-end">
      <v-btn v-if="canPrint" outlined @click="print()">{{
        $vuetify.lang.t("$vuetify.printSetting.print")
      }}</v-btn>
    </div>
    <div v-if="coa && coa.length > 0" class="mb-4">
      <v-alert v-if="missingCoaInventory && missingCoaInventory.length > 0" type="warning" > Inventory with missing CoA {{missingCoaInventory.join(', ')}} </v-alert>

      <v-card v-if="labResults">
        <v-card-title style="cursor: pointer" @click="expandCard = !expandCard">
          {{ $vuetify.lang.t("$vuetify.worksheet.foundCoa", coa.length) }}
        </v-card-title>

        <v-expand-transition>

          <v-list v-if="expandCard">
            <download class="mx-2" v-model="invertedLabResults" fileName="CoA Estimate"/>

            <v-list-item-group>
              <v-list-item v-for="element in labResults" :key="element.id">
                <v-list-item-content>
                  <v-list-item-title>{{
                    element.name
                  }}</v-list-item-title>

                  <v-list-item-subtitle>{{
                    element.symbol
                  }}</v-list-item-subtitle>
                  
                </v-list-item-content>
                <v-list-item-action>
                  <div>{{ element.percentage ? element.percentage.toFixed(6):0}}</div>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-expand-transition>
      </v-card>
    </div>

    <div class="text-caption my-2" v-if="worksheet.deductibleWeight">
      Deductible weight per entry:
      {{ worksheet.deductibleWeight | formatNumber }}
      {{ productInfo ? productInfo.measureWeight : "" }}
    </div>

    <v-data-table :items="worksheet.entries" :headers="headers">
      <template v-slot:item.index="{ index }"> {{ index + 1 }}. </template>
      <template v-slot:item.updateTime="{ item }">
        {{ item.updateTime | formatDateTime }}
      </template>
      <template v-slot:item.unit="{ item }">
        <div v-if="item.unit">
          {{ item.unit }} {{ productInfo ? productInfo.measureUnit : "" }}
          <div class="text-caption">
            {{ item.amountPerUnit | formatNumber }}
            {{ productInfo ? productInfo.measureWeight : "" }}/{{
              productInfo ? productInfo.measureUnit : ""
            }}
          </div>
        </div>
      </template>
      <template v-slot:item.grossWeight="{ item }">
        {{ item.grossWeight | formatNumber }}
        {{ productInfo ? productInfo.measureWeight : "" }}
        <div
          class="text-caption"
          :set="(inventory = getInventory(item.inventoryId))"
        >
          <div>
            {{ inventory.productName }}
          </div>
          <a
            class="link"
            @click="viewSelectedInventory(item)"
            v-if="item.inventoryId"
          >
            <span v-if="inventory.documentNumber"
              >Doc #: {{ inventory ? inventory.documentNumber : "" }} /
            </span>
            ID: {{ inventory.id }}
          </a>
        </div>
      </template>
      <template v-slot:item.adjustmentWeight="{ item }">
        {{ item.netAdjustmentWeight > 0 ? "+" : ""
        }}{{ item.netAdjustmentWeight | formatNumber }}
        {{ productInfo ? productInfo.measureWeight : "" }}
      </template>
      <template v-slot:item.netWeight="{ item }">
        {{
          (item.netWeight +
            (item.netAdjustmentWeight ? item.netAdjustmentWeight : 0))
            | formatNumber
        }}
        {{ productInfo ? productInfo.measureWeight : "" }}
      </template>
      <template v-slot:item.files="{ item }">
        <div v-if="item.files && item.files.length > 0">
          <v-btn v-if="isMobile" text @click="showImages(item)"
            >{{ item.files.length }} File</v-btn
          >
          <div class="d-flex flex-row" v-else>
            <v-img
              :class="`${item.files.length > 1 ? 'mr-2' : ''} my-2`"
              v-for="(img, index) in getEntryImages(item)"
              :key="index"
              contain
              aspect-ratio="1.7"
              max-height="100"
              :src="img.src"
              @click="showImages(item)"
            />
          </div>
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list class="pa-2" width="200px">
            <v-list-item
              @click="
                selectedEntry = item;
                adjustmentDialog = true;
              "
            >
              <v-list-item-icon><v-icon>mdi-plus</v-icon></v-list-item-icon>
              <v-list-item-content>
                {{ $vuetify.lang.t("$vuetify.base.adjustmentWeight") }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="worksheet.status == 'PENDING' && editable"
              @click="editEntry(item)"
            >
              <v-list-item-icon
                ><v-icon>mdi-pencil-outline</v-icon></v-list-item-icon
              >
              <v-list-item-content>
                {{ $vuetify.lang.t("$vuetify.base.edit") }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="worksheet.status == 'PENDING' && editable"
              @click="deleteItem(item)"
            >
              <v-list-item-icon
                ><v-icon>mdi-delete-outline</v-icon></v-list-item-icon
              >
              <v-list-item-content>
                {{ $vuetify.lang.t("$vuetify.base.delete") }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:body.append="{}">
        <tr>
          <td colspan="6">
            <div class="ma-4 text-subtitle-2">
              <div class="d-flex justify-content-between">
                <div>{{ $vuetify.lang.t("$vuetify.base.total") }}</div>
                <div class="ml-5">
                  {{ sum ? sum.netWeight : 0 | formatNumber }} {{ weightUnit }}
                </div>
              </div>
              <div
                v-if="worksheet.targetWeight && worksheet.targetWeight > 0"
                class="d-flex justify-content-between"
              >
                <div>{{ $vuetify.lang.t("$vuetify.base.target") }}</div>
                <div class="ml-5">
                  {{ worksheet.targetWeight | formatNumber }} {{ weightUnit }}
                </div>
              </div>
              <div
                v-if="worksheet.targetWeight && worksheet.targetWeight > 0"
                class="d-flex justify-content-between"
              >
                <div>{{ $vuetify.lang.t("$vuetify.base.remain") }}</div>
                <div class="ml-5">
                  {{ remainingWeight | formatNumber }} {{ weightUnit }}
                </div>
              </div>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog style="z-index: 1000" v-model="imgDialog" fullscreen>
      <v-card>
        <v-card-title class="justify-end">
          <div>
            <v-btn @click="imgDialog = null" icon
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </div>
        </v-card-title>
        <v-card-text>
          <v-carousel continuous hide-delimiter-background v-model="imgIndex" height="80vh" v-if="images && images.length > 0">
            <v-carousel-item
              v-for="(img, i) in images"
              :key="i"
              reverse-transition="fade-transition"
              transition="fade-transition"
            >
            <v-container class="fill-height align-items-center justify-content-center">
              <v-layout class="flex-column" justify-center>
                <div class="d-flex justify-space-between">
                  <div class="text-subtitle-2 mb-2">
                    {{ $vuetify.lang.t("$vuetify.base.entry") }} {{img.entryIndex}}/{{images.length}}
                  </div>
                  <div>
                    <div v-if="img.grossWeight">
                      {{ $vuetify.lang.t("$vuetify.base.grossWeight") }}:
                      {{ img.grossWeight | formatNumber }}
                      {{ productInfo ? productInfo.measureWeight : "" }}
                    </div>
                    <div v-if="img.netWeight && img.netWeight != img.grossWeight"> 
                      {{ $vuetify.lang.t("$vuetify.base.netWeight") }}:
                      {{
                        (img.netWeight +
                          (img.netAdjustmentWeight ? img.netAdjustmentWeight : 0))
                          | formatNumber
                      }}
                      {{ productInfo ? productInfo.measureWeight : "" }}
                    </div>
                  </div>
                  <div class="text-caption ma-2">
                    {{ img.time | formatDateTime }}
                  </div>
                </div>
                <v-img :src="img.src" contain
                  aspect-ratio="1.7" ref="images"/>
              </v-layout>
            </v-container>
            </v-carousel-item>
          </v-carousel>
          <div v-else class="text-subtitle-2 font-weight-light">
            No attachments found
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      max-width="300px;"
      style="z-index: 1000"
      v-model="adjustmentDialog"
    >
      <v-card>
        <v-card-title class="justify-end">
          <div>
            <v-btn @click="adjustmentDialog = null" icon
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </div>
        </v-card-title>
        <v-card-text>
          <worksheet-item-adjustment
            :key="selectedEntry ? selectedEntry.id : 0"
            @cancel="adjustmentDialog = null"
            :worksheetEntry="selectedEntry"
            :productInfo="productInfo"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <confirmation-dialog ref="confirm" />
    <v-bottom-sheet scrollable v-model="showInventoryDetails">
      <v-card>
        <v-card-title class="justify-center">
          <v-btn class="mt-6" text @click="showInventoryDetails = false">
            close
          </v-btn>
        </v-card-title>
        <v-card-text>
          <inventory-details
            :key="selectedInventory ? selectedInventory.id : 0"
            v-model="selectedInventory"
          />
          <div v-if="selectedInventoryUnit">
            <div class="text-center text-subtitle-1 my-2 font-weight-light">
              {{ this.$vuetify.lang.t("$vuetify.worksheet.selectedUnit") }}
            </div>
            <v-simple-table class="table">
              <tr>
                <td>Code</td>
                <td>{{ selectedInventoryUnit.code }}</td>
              </tr>
              <tr>
                <td>
                  # of {{ productInfo ? productInfo.measureUnit : "Unit" }}
                </td>
                <td>
                  {{ selectedInventoryUnit.unitShare }}
                  {{ productInfo ? productInfo.measureUnit : "" }}
                </td>
              </tr>
              <tr>
                <td>
                  Weight per
                  {{ productInfo ? productInfo.measureUnit : "Unit" }}
                </td>
                <td>
                  {{ selectedInventoryUnit.amountPerUnit | formatNumber }}
                  {{ selectedInventoryUnit.unitMeasure }}
                </td>
              </tr>
            </v-simple-table>
          </div>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
const WorksheetItemAdjustment = () =>
  import("@/components/processWorksheet/WorksheetItemAdjustment.vue");
const InventoryDetails = () =>
  import("@/components/inventory/InventoryDetails.vue");
import { sumWorksheetWeight } from "@/services/worksheetServices";
const Download = () => import("@/components/common/Download.vue");
import moment from 'moment';

export default {
  props: {
    worksheet: {
      type: Object,
      required: true,
    },
    worksheetTypeFields: {
      type: Object,
      required: true,
    },
    sum: {
      type: Object,
      required: true,
    },
    productInfo: {
      type: Object,
      required: false,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => {
    return {
      imgDialog: false,
      imgIndex: 0,
      adjustmentDialog: false,
      selectedEntry: null,
      inventories: [],
      showInventoryDetails: false,
      selectedInventory: null,
      selectedInventoryUnit: null,
      coa: [],
      labResults: [],
      expandCard: false,
      missingCoaInventory: []
    };
  },
  watch: {
    worksheet: {
      handler(newVal) {
        console.log(newVal);
        this.sumWeight();
        this.loadInventoryDetails();
      },
      deep: true,
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
    weightUnit() {
      return this.productInfo ? this.productInfo.measureWeight : null;
    },
    remainingWeight() {
      const target = this.worksheet.targetWeight
        ? this.worksheet.targetWeight
        : 0;
      const total = this.sum ? this.sum.netWeight : 0;
      const remainingWeight = target - total;
      return remainingWeight;
    },
    canPrint() {
      if (
        this.worksheet &&
        (this.worksheet.processDirection == "Output" ||
          this.worksheet.customerId)
      ) {
        return true;
      } else {
        return false;
      }
    },
    images() {
      const entries = this.worksheet.entries;
      let images = [];
      if (
        entries && entries.length > 0
      ) {
        entries.forEach( (entry, index) => {
          if(entry.files && entry.files.length > 0){
            const entryFiles = entry.files.map((file) => {
              const src = `${process.env.VUE_APP_BASE_URL}/file/worksheet/${file.id}`;
              return { src, id: file.id, time: file.createdTime, entryIndex: index+1, 
                entryId: entry.id, 
                grossWeight: entry.grossWeight,
                netWeight: entry.netWeight, 
                netAdjustmentWeight: entry.netAdjustmentWeight};
            })
            
            images = images.concat(entryFiles);
          }
        })
      } 
      return images;
    },
    hasUnits() {
      const hasUnit = false;

      if (this.worksheet && this.worksheet.entries) {
        const entriesWithUnits = this.worksheet.entries.filter(
          (e) => e.unit && e.unit > 0
        );
        if (entriesWithUnits && entriesWithUnits.length > 0) {
          return true;
        }
      }

      return hasUnit;
    },
    headers() {
      const headers = [
        {
          text: "",
          value: "index",
          align: "start",
          sortable: true,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.base.time"),
          value: "updateTime",
          align: "start",
          sortable: true,
        },
      ];

      if (this.hasUnits) {
        headers.push({
          text: this.$vuetify.lang.t("$vuetify.base.unit"),
          value: "unit",
          align: "start",
          sortable: true,
        });
      }

      headers.push({
        text: this.$vuetify.lang.t("$vuetify.base.grossWeight"),
        value: "grossWeight",
        align: "start",
        sortable: true,
      });

      headers.push({
        text: this.$vuetify.lang.t("$vuetify.base.adjustmentWeight"),
        value: "adjustmentWeight",
        align: "start",
        sortable: true,
      });

      headers.push({
        text: this.$vuetify.lang.t("$vuetify.base.netWeight"),
        value: "netWeight",
        align: "start",
        sortable: true,
      });

      if (this.hasNotes) {
        headers.push({
          text: this.$vuetify.lang.t("$vuetify.base.notes"),
          value: "notes",
          align: "start",
          sortable: true,
        });
      }

      if (this.hasFiles) {
        headers.push({
          text: this.$vuetify.lang.t("$vuetify.base.attachments"),
          value: "files",
          align: "start",
          sortable: true,
        });
      }

      headers.push({
        text: "",
        value: "action",
        align: "right",
        sortable: false,
      });

      return headers;
    },
    hasDeductibleWeight() {
      return (
        this.worksheetTypeFields.hasDeductibleWeight &&
        this.worksheet.deductibleWeight > 0
      );
    },
    hasFiles() {
      return this.worksheetTypeFields.hasEntryPhoto;
    },
    hasNotes() {
      return this.worksheetTypeFields.hasEntryNotes;
    },
    invertedLabResults(){
      if(this.labResults){
        const arr = this.labResults;
        const inverted = arr.reduce((acc, cur) => {
            acc[cur.symbol] = cur.percentage;
            return acc;
          }, {Date: moment().format('DD/MM/YYYY'), Time: moment().format('hh:mm:ss'), SampleNo: '', Quality: '', AverOutN: '', Program: '', QMDelete: '', dateTime: moment().format('DD/MM/YYYY hh:mm:ss'),});
        return [inverted];
      }
      else{
        return this.labResults;
      }
    }
  },
  components: {
    InventoryDetails,
    WorksheetItemAdjustment,
    Download
  },
  methods: {
    async sumWeight() {
      this.sum = await sumWorksheetWeight(this.worksheet);
      this.$emit("update:sum", this.sum);
    },
    loadInventoryDetails() {
      if (this.worksheet.entries) {
        const inventoryIds = this.worksheet.entries
          .filter((e) => e.inventoryId)
          .map((e) => e.inventoryId);
        if (inventoryIds && inventoryIds.length > 0) {
          const params = {
            ids: inventoryIds ? inventoryIds.join(",") : null,
            liteMode: false,
          };

          this.$axios
            .get("/inventory/by-ids", { params })
            .then(async (resp) => {
              this.inventories = resp.data;
              this.coa = this.getCOAWithFinalTestTrue(this.inventories);
              const coaResults = await this.fetchLabResultsForCOA(this.coa);
              this.labResults = this.calculateWeightedAveragePercentages(
                this.worksheet.entries,
                this.coa,
                coaResults
              );

              if (this.inventories && this.inventories.length > 0) {
                this.inventories.forEach((i) => {
                  if (i.fields && i.fields.length > 0) {
                    const docNumberField = i.fields.find((f) =>
                      f.field.toLowerCase().includes("document no")
                    );
                    i.documentNumber = docNumberField
                      ? docNumberField.fieldValueString
                      : "";
                  }
                });
              }
            });
        }
      }
    },
    getCOAWithFinalTestTrue(inventoryList) {
      // Create an array to hold the filtered coa objects
      let filteredCOA = [];

      // Loop through each inventory object in the inventoryList
      inventoryList.forEach((inventory) => {
        // Get the coa array from the inventory object
        const coaArray = inventory.coa;

        // Filter the coa array to only include objects with finalTest = true
        const filteredArray = coaArray ? coaArray.filter((coa) => coa && coa.finalTest === true):[];

        // Add the filtered coa objects to the filteredCOA array
        filteredCOA = filteredCOA.concat(filteredArray);
      });

      return filteredCOA;
    },
    async fetchLabResultsForCOA(coa) {
      const labTestIds = coa.map((c) => c.id);
      const params = {
        labReportIds: labTestIds.join(","),
      };
      return await this.$axios
        .get("/inventory-lab-result/get-composition-average", { params })
        .then((resp) => {
          return resp.data;
        });
    },
    getInventory(id) {
      if (this.inventories && this.inventories.length > 0) {
        return this.inventories.find((i) => i.id == id);
      } else {
        return {};
      }
    },
    async deleteItem(entry) {
      if (this.worksheet.entries) {
        let confirm = await this.$refs.confirm.open(
          "Confirm Delete",
          "Do you want to delete this entry?"
        );
        if (confirm) {
          this.$axios
            .delete("processWorksheetInput/" + entry.id)
            .then(() => {
              const index = this.worksheet.entries.findIndex(
                (e) => e.id == entry.id
              );
              this.worksheet.entries.splice(index, 1);

              this.sumWeight();
            })
            .catch((error) => {
              this.addErrorMessage(
                "Error deleting entry, " + error.data.message
              );
            });
        }
      }
    },
    editEntry(entry) {
      this.$emit("editEntry", entry);
    },
    showImages(entry) {
      this.imgDialog = true;
      this.selectedEntry = entry;
      const entryImgIndex = this.images.findIndex(i => i.entryId == entry.id);
      this.imgIndex = entryImgIndex > -1 ? entryImgIndex:0;
    },
    getEntryImages(entry) {
      if (entry && entry.files.length > 0) {
        return entry.files.map((file) => {
          const src = `${process.env.VUE_APP_BASE_URL}/file/worksheet/${file.id}`;
          return { src, id: file.id };
        });
      } else {
        return [];
      }
    },
    print() {
      if (this.worksheet.customerId) {
        this.$router.push({
          name: "worksheetPackingSlip",
          params: { id: this.worksheet.id },
        });
      } else {
        this.$router.push({
          name: "processWorksheetPrintQR",
          params: { id: this.worksheet.id },
        });
      }
    },
    viewSelectedInventory(worksheetEntry) {
      const inventoryId = worksheetEntry.inventoryId;
      const inventoryUnitId = worksheetEntry.inventoryUnitId;
      this.selectedInventory = this.getInventory(inventoryId);
      if (inventoryUnitId && this.selectedInventory.unitDetails) {
        this.selectedInventoryUnit = this.selectedInventory.unitDetails.find(
          (d) => d.id == inventoryUnitId
        );
      }
      this.showInventoryDetails = true;
    },
    calculateWeightedAveragePercentages(entries, coaList, labresults) {
      this.missingCoaInventory = []
      const result = {};

      let totalNetWeight = 0;
      const listOfElementDistributions = [];
      const symbolToNameMap = {};

      // loop through each entry to calculate the distribution of its net weight among each element
      entries.forEach((entry) => {
        
        const inventoryId = entry.inventoryId;
        const coa = coaList.find((c) => c.inventoryId == inventoryId);

        //skip calculation if entry can't be matched with a coa
        if(!coa){
          this.missingCoaInventory.push(inventoryId);
          return;
        }

        totalNetWeight += entry.netWeight;
        const coaLabResults = labresults.filter((l) => l.labTestId == coa.id);

        const elementDistribution = {};
        coaLabResults.forEach((elementResult) => {
          symbolToNameMap[elementResult.symbol] = elementResult.elementName;

          elementDistribution[elementResult.symbol] =
            entry.netWeight * (elementResult.averagePercentage / 100);
        });
        listOfElementDistributions.push(elementDistribution);
      });

      // sum the element distributions for each element to get the total amount of each element
      const totals = {};
      listOfElementDistributions.forEach((elementDistribution) => {
        Object.keys(elementDistribution).forEach((elementSymbol) => {
          if (!totals[elementSymbol]) {
            totals[elementSymbol] = 0;
          }
          totals[elementSymbol] += elementDistribution[elementSymbol];
        });
      });

      // calculate the weighted average percentage for each element and add it to the result object
      Object.keys(totals).forEach((elementSymbol) => {
        result[elementSymbol] = (totals[elementSymbol] / totalNetWeight) * 100;
      });

      // convert result object to an array
      return Object.keys(result).map((key) => {
        const name = symbolToNameMap[key];
        return { name, symbol: key, percentage: result[key] };
      });
    },
  },
  created() {
    // console.log(this.worksheet.entries);
    // debugger;
    this.sumWeight();
    this.loadInventoryDetails();
  },
};
</script>

<style scoped>
a.link {
  color: green;
}

.fullscreen {
  height: 100vh; /* set height to viewport height */
}
</style>
